.startIcon {
    background-color: rgb(14, 148, 206);
    border-color: rgb(11, 134, 187);
}

.print {
    padding-top: 3em;
}

.fullscreen {
    width: 28px;
    height: 28px;
    left: 3px;
    top: -3px;
    border-radius: 4px;
    cursor: pointer;
    border: 3px solid gray;
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}

.fullscreen:hover {
    background-color: rgb(234, 234, 234);
}

.fullscreen-inner {
    width: 10px;
    height: 10px;
    border: 3px solid gray;
    cursor: pointer;
}